<template>
  <div>
    <v-row dense>
      <v-col md="12" cols="12">
        <v-form ref="form" class="multi-col-validation">
          <v-row dense>
            <v-col md="3" cols="12">
              <v-card flat dense>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">SEARCHING SECTION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="month_of_id"
                        class="mx-2"
                        :items="month_of_items"
                        item-value="id"
                        item-text="month_of"
                        label="Month Of"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="type_of_employee"
                        class="mx-2"
                        :items="type_of_employee_items"
                        label="Type of Employee"
                        item-value="id"
                        item-text="name"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        class="mx-2"
                        v-model="category_id"
                        dense
                        label="Category"
                        :items="category_items"
                        item-value="id"
                        item-text="category"
                        :rules="rules.combobox_rule"
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="duration"
                        class="mx-2"
                        :items="['1-15', '16-30/31']"
                        label="Duration"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="batch_no"
                        class="mx-2"
                        :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
                        label="Batch"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>

                    <v-col md="12" cols="12" v-if="category_id > 0 && false">
                      <v-autocomplete
                        class="mx-2"
                        v-model="search"
                        :items="search_items"
                        :loading="isLoading"
                        @keyup.enter="searching($event.target.value)"
                        hide-no-data
                        hide-selected
                        item-text="name"
                        item-value="id"
                        label="Search"
                        placeholder="Press Enter to Search "
                        :prepend-icon="icons.mdiAccountSearchOutline"
                        @change="get_search_items_info"
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="12" cols="12">
                      <h2>List of Employee</h2>
                      <v-data-table
                        dense
                        class="mt-2"
                        :headers="headers"
                        :items="employee_items"
                        :height="200"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item="{ item }">
                          <tr @click="activerow(item)"
                              :class="{ 'info white--text': item.id === selectedDepositId }">
                            <td class="text-center">
                              {{ item.last_name + ',' + item.first_name + ' ' + item.middle_name }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="9" cols="12">
              <v-card flat dense>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">EMPLOYEE'S INFORMATION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="full_name"
                        label="Full Name"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="branch"
                        label="Branch"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="position"
                        label="Position"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="status"
                        label="Status"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="date_of_employment"
                        label="Date of Employment"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="employment_duration"
                        label="Employee Duration"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row>
                <v-col md="5" cols="12">
                  <v-card flat dense>
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">COMPENSATION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-if="category_text != 'BOD'"
                            v-model="compensation_rate"
                            label="Compensation Rate"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-if="category_text != 'BOD'"
                            v-model="no_of_days"
                            label="No. of Days"
                            dense
                            outlined
                            type="number"
                            min="0"
                            max="31"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-model="holiday_pay"
                            label="Holiday Pay"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-if="holiday_pay > 0"
                            v-model="holiday_pay_details"
                            label="Holiday Details"
                            dense
                            outlined
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                          ></v-text-field>
                        </v-col>

                        <v-col md="6" cols="12">
                          <v-text-field
                            v-model="rice_allowance"
                            label="Rice Allowance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-model="birthday_allowances"
                            label="Birthday Allowance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-model="trainee_allowances"
                            label="Trainee Allowance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-model="incentives_allowances"
                            label="Incentives Allowance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            v-if="category_text === 'BOD'"
                            v-model="bod_allowance"
                            label="BOD ALLOWANCE"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            background-color="info"
                            style="font-size: x-large"
                            v-model="gross_salary_formated"
                            label="Gross Salary"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="7" cols="12">
                  <v-card flat dense>
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">SALARY DEDUCTION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-card flat dense>
                        <v-toolbar dense dark color="primary">
                          <v-toolbar-title><h5 class="font-weight-light">1st PRIORITY</h5>
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="mt-3">
                          <v-row dense>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="status != 'Trainee'"
                                v-model="deduction_sss"
                                label="SSS #"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="status != 'Trainee'"
                                v-model="deduction_philhealth"
                                label="PhilHealth"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="status != 'Trainee'"
                                v-model="deduction_pagibig"
                                label="Pag-Ibig"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_coop_loan"
                                label="COOP Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="read_plan_only"
                                v-model="deduction_plan"
                                label="Plan"
                                dense
                                outlined
                                readonly
                              ></v-text-field>
                              <v-text-field
                                v-else
                                v-model="deduction_plan"
                                label="Damayan Plan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_late"
                                label="Late"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="no_of_absent"
                                label="No. of Absent"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="no_of_related_attendance"
                                label="No. of Days Related to Attendance"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_tax"
                                label="TAX"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_cash_bond"
                                label="Cash Bond"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_sss_loan"
                                label="SSS Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_pagibig_loan"
                                label="Pag-Ibig Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_others"
                                label="Others"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="deduction_others > 0"
                                v-model="other_details"
                                label="Specify Others"
                                dense
                                outlined
                                :rules="rules.default_max_45_character_and_no_empty_rule"
                              ></v-text-field>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_coop_save"
                                label="COOP Save"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card flat dense>
                        <v-toolbar dense dark color="primary">
                          <v-toolbar-title><h5 class="font-weight-light">2nd PRIORITY</h5>
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="mt-3">
                          <v-row dense>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="read_loans_only"
                                v-model="deduction_loan"
                                label="Accounting Loans"
                                dense
                                outlined
                                readonly
                              ></v-text-field>
                              <v-text-field
                                v-else
                                v-model="deduction_loan"
                                label="Accounting Loans"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="read_auto_loans_only"
                                v-model="deduction_auto_loan"
                                label="Auto Loan"
                                dense
                                outlined
                                readonly
                              ></v-text-field>
                              <v-text-field
                                v-else
                                v-model="deduction_auto_loan"
                                label="Auto Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="read_ca_only"
                                v-model="deduction_ca"
                                label="Cash Advance"
                                dense
                                outlined
                                readonly
                              ></v-text-field>
                              <v-text-field
                                v-else
                                v-model="deduction_ca"
                                label="Cash Advance"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="read_motor_only"
                                v-model="deduction_motor"
                                label="Motor Finance"
                                dense
                                outlined
                                readonly
                              ></v-text-field>
                              <v-text-field
                                v-else
                                v-model="deduction_motor"
                                label="Motor Finance"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="read_other_only"
                                v-model="deduction_orec"
                                label="Other Receivable"
                                dense
                                outlined
                                readonly
                              ></v-text-field>
                              <v-text-field
                                v-else
                                v-model="deduction_orec"
                                label="Other Receivable"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-row dense>
                        <v-col md="12" cols="12">
                          <v-text-field
                            background-color="warning"
                            style="font-size: xx-large"
                            v-model="net_salary_formated"
                            label="Net Salary"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" md="12" v-if="!is_final">
                  <v-btn class="w-full" color="primary" @click="save_employee" v-if="!saving"> Save
                    Salary
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="saving"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col md="12" cols="12">
        <v-card flat dense>
          <v-card-text class="mt-3">
            <v-row dense>
              <v-col md="12" cols="12">
                <h2>
                  Salaries Data
                  <v-btn class="mt-2" color="success" @click="final_salary"
                         v-if="!saving && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="saving"
                  ></v-progress-circular>
                </h2>
                <v-simple-table dense class="mt-2">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-center text-uppercase">Particulars</th>
                      <th class="text-center text-uppercase">Name</th>
                      <th class="text-center text-uppercase">Branch</th>
                      <th class="text-center text-uppercase">Position</th>
                      <th class="text-center text-uppercase">Gross</th>
                      <th class="text-center text-uppercase">Allowances</th>
                      <th class="text-center text-uppercase">Deductions</th>
                      <th class="text-center text-uppercase">Net</th>
                      <th class="text-center text-uppercase">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in salaries_items" :key="item.id">
                      <td class="text-center">
                        {{ item.particulars }}
                      </td>
                      <td class="text-center">
                        {{
                        item.employee.last_name + ',' + item.employee.first_name + ' ' +
                        item.employee.middle_name
                        }}
                      </td>
                      <td class="text-center">
                        {{ item.branch }}
                      </td>
                      <td class="text-center">
                        {{ item.position }}
                      </td>
                      <td class="text-center">
                        {{
                        formatPrice(parseFloat(item.gross_salary)-(parseFloat(item.rice_allowance) +
                        parseFloat(item.trainee_allowances) +
                        parseFloat(item.birthday_allowances) +
                        parseFloat(item.transpo_allowances) +
                        parseFloat(item.accomodation_allowances) +
                        parseFloat(item.incentives_allowances) +
                        parseFloat(item.holiday_pay))) }}
                      </td>
                      <td class="text-center">
                        {{
                        formatPrice(
                        parseFloat(item.rice_allowance) +
                        parseFloat(item.trainee_allowances) +
                        parseFloat(item.birthday_allowances) +
                        parseFloat(item.transpo_allowances) +
                        parseFloat(item.accomodation_allowances) +
                        parseFloat(item.incentives_allowances) +
                        parseFloat(item.holiday_pay),
                        )
                        }}
                      </td>
                      <td class="text-center">
                        {{
                        formatPrice(
                        parseFloat(item.absent_amount) +
                        parseFloat(item.related_attendance_amount) +
                        parseFloat(item.deduction_sss) +
                        parseFloat(item.deduction_philhealth) +
                        parseFloat(item.deduction_pagibig) +
                        parseFloat(item.deduction_loan) +
                        parseFloat(item.deduction_auto_loan) +
                        parseFloat(item.deduction_coop_loan) +
                        parseFloat(item.deduction_late) +
                        parseFloat(item.deduction_coop_save) +
                        parseFloat(item.deduction_ca) +
                        parseFloat(item.deduction_plan) +
                        parseFloat(item.deduction_others) +
                        parseFloat(item.deduction_tax) +
                        parseFloat(item.deduction_orec) +
                        parseFloat(item.deduction_cash_bond) +
                        parseFloat(item.deduction_sss_loan) +
                        parseFloat(item.deduction_pagibig_loan) +
                        parseFloat(item.deduction_motor),
                        )
                        }}
                      </td>
                      <td class="text-center">
                        {{ formatPrice(item.net_salary) }}
                      </td>
                      <td class="text-center">
                        <div v-if="item.is_approved === 0">
                          <v-icon class="mr-2" color="error" @click="actions(item)">
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="edit_dialog" persistent max-width="80%">
      <v-card>
        <edit-salaries-components v-on:data="selected_month" :key="this.key"
                                  :value="this.edit_value"
                                  :month_of_id="this.month_of_id"
                                  :duration="this.duration"
        >
        </edit-salaries-components>
      </v-card>
      <v-btn class="mt-4" color="error" @click="edit_dialog = false"> Close</v-btn>
    </v-dialog>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiPencilOutline} from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import EditSalariesComponents
    from '@/views/input_interface/salary_and_thirten_pay/salaries_wages/EditSalariesComponents'

  const initialState = () => {
    return {
      key: 0,
      saving: false,
      alert: false,
      alert_message: '',
      search_member: [],

      edit_dialog: false,
      edit_value: {},

      month_of_id: '',
      month_of_items: [],
      type_of_employee_items: [
        {id: 1, name: 'Active'},
        {id: 2, name: 'Temporary Deactivate'},
        {id: 3, name: 'Permanent Deactivated'},
      ],
      type_of_employee: '',
      category_id: '',
      category_items: [],
      duration: '',
      batch_no: '',
      isLoading: false,
      search: '',
      search_items: [],
      employee_items: [],
      full_name: '',
      branch: '',
      position: '',
      status: '',
      date_of_employment: '',
      employment_duration: '',
      employment_length: '',
      compensation_rate: 0,
      no_of_days: 0,
      holiday_pay: 0,
      holiday_pay_details: '',
      bod_allowance: 0,
      rice_allowance: 0,
      birthday_allowances: 0,
      trainee_allowances: 0,
      incentives_allowances: 0,
      gross_salary: 0,
      gross_salary_formated: '0.00',
      deduction_sss: 0,
      deduction_loan: 0,
      deduction_auto_loan: 0,
      deduction_philhealth: 0,
      deduction_ca: 0,
      deduction_coop_loan: 0,
      deduction_pagibig: 0,
      deduction_plan: 0,
      deduction_coop_save: 0,
      deduction_late: 0,
      no_of_related_attendance: 0,
      deduction_tax: 0,
      no_of_absent: 0,
      deduction_others: 0,
      deduction_motor: 0,
      deduction_orec: 0,
      deduction_cash_bond: 0,
      related_attendance_amount: 0,
      absent_amount: 0,
      deduction_sss_loan: 0,
      deduction_pagibig_loan: 0,
      other_details: '',
      net_salary: 0,
      net_salary_formated: '0.00',
      salaries_items: [],
      data: [],
      selectedDepositId: -1,
      particulars: '',
      category_text: '',
      bank_type: '',
      bank_account_name: '',
      bank_account_no: '',
      headers: [{text: 'NAME', value: 'id', sortable: false}],

      read_loans_only: false,
      read_auto_loans_only: false,
      read_ca_only: false,
      read_other_only: false,
      read_motor_only: false,
      read_plan_only: false,
      read_coop_save_only: false,

      is_final: false,
      orig_deduction_auto_loan: 0,
      orig_deduction_ca: 0,
      orig_deduction_loan: 0,
      orig_deduction_orec: 0,
      orig_deduction_motor: 0,
      orig_deduction_plan: 0,
      orig_deduction_coop_save: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
      EditSalariesComponents,
    },
    setup() {
      return {
        icons: {
          mdiPencilOutline,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['name', 'employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', [
        'initialize_salaries_employee',
        'salaries_data',
        'register_new_salary',
        'salaries_data_approved',
      ]),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        this.saving = false
        this.particulars = ''
        this.full_name = ''
        this.branch = ''
        this.position = ''
        this.status = ''
        this.date_of_employment = ''
        this.employment_duration = ''
        this.employment_length = ''
        this.bank_type = ''
        this.bank_account_name = ''
        this.bank_account_no = ''
        this.compensation_rate = 0
        this.no_of_days = 0
        this.holiday_pay = 0
        this.holiday_pay_details = ''
        this.bod_allowance = 0
        this.rice_allowance = 0
        this.birthday_allowances = 0
        this.trainee_allowances = 0
        this.incentives_allowances = 0
        this.gross_salary = 0
        this.gross_salary_formated = '0.00'
        this.deduction_sss = 0
        this.deduction_loan = 0
        this.deduction_auto_loan = 0
        this.deduction_philhealth = 0
        this.deduction_ca = 0
        this.deduction_coop_loan = 0
        this.deduction_pagibig = 0
        this.deduction_plan = 0
        this.deduction_coop_save = 0
        this.deduction_late = 0
        this.deduction_tax = 0
        this.deduction_motor = 0
        this.deduction_orec = 0
        this.related_attendance_amount = 0
        this.absent_amount = 0
        this.deduction_cash_bond = 0
        this.deduction_sss_loan = 0
        this.deduction_pagibig_loan = 0
        this.no_of_absent = 0
        this.no_of_related_attendance = 0
        this.deduction_others = 0
        this.other_details = ''
        this.net_salary = 0
        this.net_salary_formated = '0.00'
        this.selectedDepositId = -1
        this.orig_deduction_auto_loan = 0
        this.orig_deduction_ca = 0
        this.orig_deduction_loan = 0
        this.orig_deduction_orec = 0
        this.orig_deduction_motor = 0
        this.orig_deduction_plan = 0
        this.orig_deduction_coop_save = 0
      },
      activerow(value) {
        this.data = value
        this.selectedDepositId = value.id
        this.full_name = value.last_name + ', ' + value.first_name + ' ' + value.middle_name
        this.branch = value.branch.branch_code
        this.position = value.employee_position.position
        this.status = value.status
        this.date_of_employment = moment(value.date_of_employment, 'YYYY-MM-DD').format('MMMM DD, YYYY')
        var a = moment()
        var b = moment(value.date_of_employment, 'YYYY-MM-DD')

        var years = a.diff(b, 'year')
        b.add(years, 'years')

        var months = a.diff(b, 'months')
        b.add(months, 'months')

        var days = a.diff(b, 'days')
        if (days < 10) {
          days = '0' + days
        }
        this.employment_duration = years + ' years ' + months + ' months ' + days + ' days'
        this.employment_length = years + '' + months + '' + days
        this.compensation_rate = value.compensation_rate
        this.particulars = 'Sal.'
        if (value.is_allowance_only || this.category_text === 'BOD') {
          this.particulars = 'Allow.'
        }
        this.deduction_cash_bond = 0
        if (this.category_text != 'BOD') {
          if (value.employee_position.is_have_cash_bond) {
            this.deduction_cash_bond = 100
            if (this.category_text === 'Coop') {
              this.deduction_cash_bond = 200
            }
          }
        }
        // this.deduction_pagibig = 0
        // this.deduction_philhealth = 0
        // if (this.duration === '1-15' && this.status != 'Trainee') {
        //   this.deduction_pagibig = 200
        //   this.deduction_philhealth = 250
        // }
        this.bod_allowance = 0
        this.rice_allowance = 0
        if (value.is_qualified_rice === 1) {
          this.rice_allowance = 500
        }
        this.bank_type = value.bank_type_1
        this.bank_account_name = value.bank_account_1
        this.bank_account_no = value.bank_account_no_1
        var sss_d = 0
        var au_loan = 0
        var ca_de = 0
        var act_l = 0
        var o_recs = 0
        var moto_f = 0
        var plan_f = 0
        var coop_saveeee = 0
        var coop_loaann = 0
        var sss_loann = 0
        var pag_ibig_loann = 0
        var pag_ibig = 0
        var philHealth = 0
        var tax = 0
        if (value.salary_deductions.length > 0) {
          value.salary_deductions.forEach(function (item) {
            switch (item.type) {
              case 'SSS':
                sss_d = parseFloat(sss_d) + parseFloat(item.amount)
                break
              case 'Auto Loan':
                au_loan = parseFloat(au_loan) + parseFloat(item.amount)
                break
              case 'Cash Advance':
                ca_de = parseFloat(ca_de) + parseFloat(item.amount)
                break
              case 'Accounting Loan':
                act_l = parseFloat(act_l) + parseFloat(item.amount)
                break
              case 'Other Receivable':
                o_recs = parseFloat(o_recs) + parseFloat(item.amount)
                break
              case 'Motor Finance':
                moto_f = parseFloat(moto_f) + parseFloat(item.amount)
                break
              case 'Plan':
                plan_f = parseFloat(plan_f) + parseFloat(item.amount)
                break
              case 'Coop Save':
                coop_saveeee = parseFloat(coop_saveeee) + parseFloat(item.amount)
                break
              case 'Coop Loan':
                coop_loaann = parseFloat(coop_loaann) + parseFloat(item.amount)
                break
              case 'SSS Loan':
                sss_loann = parseFloat(sss_loann) + parseFloat(item.amount)
                break
              case 'Pag-ibig Loan':
                pag_ibig_loann = parseFloat(pag_ibig_loann) + parseFloat(item.amount)
                break
              case 'Tax':
                tax = parseFloat(tax) + parseFloat(item.amount)
                break
              case 'Pag-ibig':
                pag_ibig = parseFloat(tax) + parseFloat(item.amount)
                break
              case 'PhilHealth':
                philHealth = parseFloat(tax) + parseFloat(item.amount)
                break
            }
          })
        }
        var can_eeddit = (this.employee_id === 2 || this.employee_id === 1);
        if (au_loan > 0 && !can_eeddit) {
          this.read_auto_loans_only = true
        }
        if (ca_de > 0 && !can_eeddit) {
          this.read_ca_only = true
        }
        if (act_l > 0 && !can_eeddit) {
          this.read_loans_only = true
        }
        if (o_recs > 0 && !can_eeddit) {
          this.read_other_only = true
        }
        if (moto_f > 0 && !can_eeddit) {
          this.read_motor_only = true
        }
        if (plan_f > 0 && !can_eeddit) {
          this.read_plan_only = true
        }
        if (coop_saveeee > 0 && !can_eeddit) {
          this.read_coop_save_only = true
        }
        this.deduction_auto_loan = au_loan
        this.deduction_ca = ca_de
        this.deduction_loan = act_l
        this.deduction_orec = o_recs
        this.deduction_motor = moto_f
        this.deduction_plan = plan_f
        this.deduction_coop_save = coop_saveeee
        this.deduction_sss = sss_d
        this.deduction_sss_loan = sss_loann
        this.deduction_coop_loan = coop_loaann
        this.deduction_pagibig_loan = pag_ibig_loann
        this.deduction_pagibig = pag_ibig
        this.deduction_philhealth = philHealth
        this.deduction_tax = tax

        this.orig_deduction_auto_loan = au_loan
        this.orig_deduction_ca = ca_de
        this.orig_deduction_loan = act_l
        this.orig_deduction_orec = o_recs
        this.orig_deduction_motor = moto_f
        this.orig_deduction_plan = plan_f
        this.orig_deduction_coop_save = coop_saveeee
        this.computation_of_salary()
      },
      selected_month() {
        this.reset()
        this.edit_dialog = false
        this.category_text = ''
        if (this.category_items.length > 0) {
          var index = this.category_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.category_id)
          if (index != -1) {
            this.category_text = this.category_items[index].category
          }
        }
        const data = new FormData()
        data.append('month_of_id', this.month_of_id)
        data.append('duration', this.duration)
        data.append('category_id', this.category_id)
        data.append('batch_no', this.batch_no)
        data.append('is_active', this.type_of_employee)
        this.salaries_data(data)
          .then(response => {
            this.salaries_items = response.data[0].salaries_data
            this.employee_items = response.data[0].employees_data
            this.no_of_days = response.data[0].no_of_days
            this.is_final = response.data[0].is_final
          })
          .catch(error => {
            console.log(error)
          })
      },
      computation_of_salary() {
        this.check_if_input_number_correct()

        this.gross_salary =
          parseFloat(this.no_of_days) * parseFloat(this.compensation_rate) +
          parseFloat(this.bod_allowance) +
          parseFloat(this.holiday_pay) +
          parseFloat(this.rice_allowance) +
          parseFloat(this.birthday_allowances) +
          parseFloat(this.trainee_allowances) +
          parseFloat(this.incentives_allowances)

        this.absent_amount = parseFloat(this.no_of_absent) * parseFloat(this.compensation_rate)
        this.related_attendance_amount = parseFloat(this.no_of_related_attendance) * parseFloat(this.compensation_rate)
        var deductions =
          parseFloat(this.deduction_sss) +
          parseFloat(this.deduction_pagibig) +
          parseFloat(this.deduction_philhealth) +
          parseFloat(this.deduction_pagibig_loan) +
          parseFloat(this.deduction_sss_loan) +
          parseFloat(this.deduction_cash_bond) +
          parseFloat(this.deduction_coop_loan) +
          parseFloat(this.deduction_tax) +
          parseFloat(this.deduction_plan) +
          parseFloat(this.deduction_late) +
          parseFloat(this.absent_amount) +
          parseFloat(this.related_attendance_amount) +
          parseFloat(this.deduction_coop_save) +
          parseFloat(this.deduction_others)

        var fourty = parseFloat(this.gross_salary) * 0.4;
        var sixty = parseFloat(this.gross_salary) * 0.6;
        var should_net = (parseFloat(this.gross_salary) - parseFloat(deductions));


        var remaining = should_net - fourty;
        this.deduction_loan = ((parseFloat(this.orig_deduction_loan) > 0 ? parseFloat(this.orig_deduction_loan) : (parseFloat(this.deduction_loan))));
        this.deduction_auto_loan = ((parseFloat(this.orig_deduction_auto_loan) > 0 ? parseFloat(this.orig_deduction_auto_loan) : (parseFloat(this.deduction_auto_loan))));
        this.deduction_ca = ((parseFloat(this.orig_deduction_ca) > 0 ? parseFloat(this.orig_deduction_ca) : (parseFloat(this.deduction_ca))));
        this.deduction_motor = ((parseFloat(this.orig_deduction_motor) > 0 ? parseFloat(this.orig_deduction_motor) : (parseFloat(this.deduction_motor))));
        this.deduction_orec = ((parseFloat(this.orig_deduction_orec) > 0 ? parseFloat(this.orig_deduction_orec) : (parseFloat(this.deduction_orec))));


        var counter = 0
        counter += parseFloat(this.deduction_loan) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_auto_loan) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_ca) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_motor) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_orec) > 0 ? 1 : 0;
        var ded_eq = Math.floor(remaining / counter);
        if (remaining > 0) {
          if (ded_eq > 0) {
            this.deduction_loan = (parseFloat(this.orig_deduction_loan) > ded_eq || parseFloat(this.deduction_loan) > ded_eq ? ded_eq : this.deduction_loan);
            this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > ded_eq || parseFloat(this.deduction_auto_loan) > ded_eq ? ded_eq : this.deduction_auto_loan);
            this.deduction_ca = (parseFloat(this.orig_deduction_ca) > ded_eq || parseFloat(this.deduction_ca) > ded_eq ? ded_eq : this.deduction_ca);
            this.deduction_motor = (parseFloat(this.orig_deduction_motor) > ded_eq || parseFloat(this.deduction_motor) > ded_eq ? ded_eq : this.deduction_motor);
            this.deduction_orec = (parseFloat(this.orig_deduction_orec) > ded_eq || parseFloat(this.deduction_orec) > ded_eq ? ded_eq : this.deduction_orec);

            var second = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
              parseFloat(this.deduction_ca) +
              parseFloat(this.deduction_motor) +
              parseFloat(this.deduction_orec)
            var remaining2 = (remaining - second);
            if (remaining2 > 0) {
              var ded_eq2 = Math.floor(remaining2 / counter);
              if (ded_eq2 > 0) {
                this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_loan);
                this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_auto_loan);
                this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_ca);
                this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_motor);
                this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_orec);

                var third = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
                  parseFloat(this.deduction_ca) +
                  parseFloat(this.deduction_motor) +
                  parseFloat(this.deduction_orec)
                var remaining3 = (remaining - third);
                if (remaining3 > 0) {
                  var ded_eq3 = Math.floor(remaining3 / counter);
                  if (ded_eq3 > 0) {
                    this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_loan);
                    this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_auto_loan);
                    this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_ca);
                    this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_motor);
                    this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_orec);

                    var four = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
                      parseFloat(this.deduction_ca) +
                      parseFloat(this.deduction_motor) +
                      parseFloat(this.deduction_orec)
                    var remaining4 = (remaining - four);
                    if (remaining4 > 0) {
                      var ded_eq4 = Math.floor(remaining4 / counter);
                      if (ded_eq4 > 0) {
                        this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_loan);
                        this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_auto_loan);
                        this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_ca);
                        this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_motor);
                        this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_orec);


                        var five = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
                          parseFloat(this.deduction_ca) +
                          parseFloat(this.deduction_motor) +
                          parseFloat(this.deduction_orec)
                        var remaining5 = (remaining - five);
                        if (remaining5 > 0) {
                          var ded_eq5 = Math.floor(remaining5 / counter);
                          if (ded_eq5 > 0) {
                            this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_loan);
                            this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_auto_loan);
                            this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_ca);
                            this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_motor);
                            this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_orec);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            this.deduction_loan = 0
            this.deduction_auto_loan = 0
            this.deduction_ca = 0
            this.deduction_motor = 0
            this.deduction_orec = 0
          }
        } else {
          this.deduction_loan = 0
          this.deduction_auto_loan = 0
          this.deduction_ca = 0
          this.deduction_motor = 0
          this.deduction_orec = 0
        }
        var deductions2 = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
          parseFloat(this.deduction_ca) +
          parseFloat(this.deduction_motor) +
          parseFloat(this.deduction_orec)
        this.net_salary = parseFloat(this.gross_salary) - (parseFloat(deductions) + parseFloat(deductions2))

        this.gross_salary_formated = this.formatPrice(this.gross_salary)
        this.net_salary_formated = this.formatPrice(this.net_salary)
      },
      check_if_input_number_correct() {
        if (this.no_of_days > 31 || this.no_of_days < 0 || this.no_of_days === '') {
          this.no_of_days = 0
        }
        if (this.holiday_pay < 0 || this.holiday_pay === '') {
          this.holiday_pay = 0
        }
        if (this.rice_allowance < 0 || this.rice_allowance === '') {
          this.rice_allowance = 0
        }
        if (this.birthday_allowances < 0 || this.birthday_allowances === '') {
          this.birthday_allowances = 0
        }
        if (this.trainee_allowances < 0 || this.trainee_allowances === '') {
          this.trainee_allowances = 0
        }
        if (this.incentives_allowances < 0 || this.incentives_allowances === '') {
          this.incentives_allowances = 0
        }
        if (this.bod_allowance < 0 || this.bod_allowance === '') {
          this.bod_allowance = 0
        }
        if (this.deduction_sss < 0 || this.deduction_sss === '') {
          this.deduction_sss = 0
        }
        if (this.deduction_loan < 0 || this.deduction_loan === '') {
          this.deduction_loan = 0
        }
        if (this.deduction_auto_loan < 0 || this.deduction_auto_loan === '') {
          this.deduction_auto_loan = 0
        }
        if (this.deduction_philhealth < 0 || this.deduction_philhealth === '') {
          this.deduction_philhealth = 0
        }
        if (this.deduction_ca < 0 || this.deduction_ca === '') {
          this.deduction_ca = 0
        }
        if (this.deduction_coop_loan < 0 || this.deduction_coop_loan === '') {
          this.deduction_coop_loan = 0
        }
        if (this.deduction_pagibig < 0 || this.deduction_pagibig === '') {
          this.deduction_pagibig = 0
        }
        if (this.deduction_plan < 0 || this.deduction_plan === '') {
          this.deduction_plan = 0
        }
        if (this.deduction_coop_save < 0 || this.deduction_coop_save === '') {
          this.deduction_coop_save = 0
        }
        if (this.deduction_late < 0 || this.deduction_late === '') {
          this.deduction_late = 0
        }
        if (this.no_of_absent < 0 || this.no_of_absent === '') {
          this.no_of_absent = 0
        }
        if (this.no_of_related_attendance < 0 || this.no_of_related_attendance === '') {
          this.no_of_related_attendance = 0
        }
        if (this.deduction_tax < 0 || this.deduction_tax === '') {
          this.deduction_tax = 0
        }
        if (this.deduction_motor < 0 || this.deduction_motor === '') {
          this.deduction_motor = 0
        }
        if (this.deduction_orec < 0 || this.deduction_orec === '') {
          this.deduction_orec = 0
        }
        if (this.deduction_cash_bond < 0 || this.deduction_cash_bond === '') {
          this.deduction_cash_bond = 0
        }
        if (this.deduction_pagibig_loan < 0 || this.deduction_pagibig_loan === '') {
          this.deduction_pagibig_loan = 0
        }
        if (this.deduction_sss_loan < 0 || this.deduction_sss_loan === '') {
          this.deduction_sss_loan = 0
        }
        if (this.deduction_others < 0 || this.deduction_others === '') {
          this.deduction_others = 0
        }
      },
      searching(value) {
        this.search_members_unpaid({
          search_word: value,
        }).then(response => {
          this.search_items = response.data
        })
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.selected_search_items = this.search_items[index]
            this.id_no = this.selected_search_items.id_no
            this.name = this.selected_search_items.name
          } else {
            this.name = ''
            this.id_no = ''
          }
        } else {
          this.name = ''
          this.id_no = ''
        }
      },
      actions(item) {
        this.edit_dialog = true
        this.edit_value = item
        this.key++
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id)
          data.append('employee_id', this.selectedDepositId)
          data.append('month_of_id', this.month_of_id)

          data.append('particulars', this.particulars)

          data.append('duration', this.duration)
          data.append('batch_no', this.batch_no)
          data.append('branch', this.branch)
          data.append('position', this.position)
          data.append('status', this.status)
          data.append('employment_duration', this.employment_duration)
          data.append('employment_length', parseInt(this.employment_length))
          data.append('bank_type', this.bank_type)
          data.append('bank_account_name', this.bank_account_name)
          data.append('bank_account_no', this.bank_account_no)

          data.append('no_of_days', this.no_of_days)
          data.append('compensation_rate', this.compensation_rate)
          data.append('gross_salary', this.gross_salary)

          data.append('bod_allowance', this.bod_allowance)
          data.append('rice_allowance', this.rice_allowance)
          data.append('trainee_allowances', this.trainee_allowances)
          data.append('birthday_allowances', this.birthday_allowances)
          data.append('transpo_allowances', 0)
          data.append('accomodation_allowances', 0)
          data.append('incentives_allowances', this.incentives_allowances)
          data.append('holiday_pay', this.holiday_pay)
          data.append('holiday_pay_details', this.holiday_pay_details)

          data.append('no_of_absent', this.no_of_absent)
          data.append('no_of_related_attendance', this.no_of_related_attendance)
          data.append('absent_amount', this.absent_amount)
          data.append('related_attendance_amount', this.related_attendance_amount)

          data.append('deduction_sss', this.deduction_sss)
          data.append('deduction_philhealth', this.deduction_philhealth)
          data.append('deduction_pagibig', this.deduction_pagibig)
          data.append('deduction_loan', this.deduction_loan)
          data.append('deduction_auto_loan', this.deduction_auto_loan)
          data.append('deduction_coop_loan', this.deduction_coop_loan)
          data.append('deduction_late', this.deduction_late)
          data.append('deduction_coop_save', this.deduction_coop_save)
          data.append('deduction_ca', this.deduction_ca)
          data.append('deduction_plan', this.deduction_plan)
          data.append('deduction_others', this.deduction_others)
          data.append('other_details', this.other_details)
          data.append('deduction_tax', this.deduction_tax)
          data.append('deduction_motor', this.deduction_motor)
          data.append('deduction_pagibig_loan', this.deduction_pagibig_loan)
          data.append('deduction_sss_loan', this.deduction_sss_loan)
          data.append('deduction_orec', this.deduction_orec)
          data.append('deduction_cash_bond', this.deduction_cash_bond)
          data.append('type_of_employee', this.type_of_employee_items[this.type_of_employee_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.type_of_employee)].name)
          data.append('net_salary', this.net_salary)

          data.append('prepared_by', this.name)

          this.register_new_salary(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.selected_month()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      final_salary() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('category_id', this.category_id)
        data.append('month_of_id', this.month_of_id)
        data.append('duration', this.duration)
        data.append('batch_no', this.batch_no)
        this.salaries_data_approved(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.selected_month()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
    },
  }
</script>
